import {Button, Heading, Modal, Text, Tile} from '@kakaoent/ops-design';
import cn from 'classnames';
import React from 'react';
import {BrowserView, MobileView} from 'react-device-detect';
import ContactGrid from './ContractGrid';

export interface ModalContractProps {
  open: boolean;
  onClose?: () => void;
}

const ModalContract: React.FC<ModalContractProps> = function ({open, onClose}) {
  return (
    <Modal
      open={open}
      onClose={() => {
        onClose?.();
      }}
    >
      <Tile
        className={cn(
          'h-full',
          'overflow-hidden',
          'flex',
          'flex-col',
          'items-center',
          'justify-center',
          'bg-transparent',
          'pointer-events-none'
        )}
      >
        <Tile
          className={cn(
            'm-[0.75rem]',
            'cardBox',
            'flex',
            'flex-col',
            'overflow-auto',
            'pointer-events-auto'
          )}
        >
          <Tile
            className={cn(
              'cardBoxHeader',
              'px-5',
              'md:px-15',
              'py-10',
              'relative'
            )}
          >
            <BrowserView>
              <Heading
                level={2}
                className={cn(
                  'my-[1.25rem]',
                  'text-[1.625rem]',
                  'leading-[1.31]'
                )}
                bold
              >
                카카오페이지 제휴 계약 문의
              </Heading>
            </BrowserView>
            <MobileView>
              <Heading level={3} className={cn('my-[1.25rem]')} bold>
                카카오페이지 제휴 계약 문의
              </Heading>
            </MobileView>
            <Button
              className={cn(
                'absolute',
                'top-[1.5rem]',
                'right-[1.5rem]',
                'h-[1.5rem]',
                'w-[1.5rem]'
              )}
              variant={'text'}
              onClick={() => onClose?.()}
              p={0}
            >
              <img
                alt="close"
                src={'/icons/ic_popup_close.svg'}
                width={24}
                height={24}
              />
            </Button>
            <Text
              className={cn('text-sm', 'text-secondary', 'leading-[1.125rem]')}
              fontSize={12}
              fontColor={'secondary'}
            >
              카카오페이지 파트너사이트 방문을 환영합니다.
              <br />
              {`카카오페이지는 "세상의 모든 이야기"를 함께 만들어 갈 파트너를 기다리고 있습니다.`}
              <br />
              제휴/계약관련 문의는 아래 문의처로 연락주시기 바랍니다.
            </Text>
          </Tile>
          <Tile
            className={cn(
              'cardBoxBody',
              'px-5',
              'md:px-15',
              'md:py-5',
              'divide-y'
            )}
          >
            <ContactGrid
              title={'콘텐츠 제휴 문의'}
              contacts={[
                {
                  depart: '웹소설사업팀 (판타지, 무협, 현대판타지, 드라마)',
                  email: 'fantasy_novel@kakaoent.com',
                },
                {
                  depart: '웹소설사업팀 (로맨스, 로맨스판타지, BL)',
                  email: 'romance_novel@kakaoent.com',
                },
                {
                  depart: '웹소설사업팀 (일반도서/책)',
                  email: 'bookbizteam@kakaoent.com',
                },
                {depart: '코믹사업팀', email: 'comic_md@kakaoent.com'},
                {
                  depart: '카카오페이지 웹툰제작팀',
                  email: 'comic_pd@kakaoent.com',
                },
              ]}
            />
            <ContactGrid
              title={'광고 • 마케팅 제휴문의'}
              contacts={[
                {depart: '광고 제휴 문의', email: 'adteam@kakaoent.com'},
                {depart: '마케팅 제휴 문의', email: 'marketing@kakaoent.com'},
              ]}
            />
            <ContactGrid
              title={'기타 문의'}
              contacts={[
                {depart: '발행자 문의', email: 'support@kakaopage.com'},
                {depart: '정산 문의', email: 'payment@kakaopage.com'},
                {depart: '기타 제휴/협력 문의', email: 'support@kakaopage.com'},
              ]}
            />
          </Tile>
        </Tile>
      </Tile>
    </Modal>
  );
};

export default ModalContract;
