'use client';

import {
  Hidden,
  IListTableBodyProps,
  IListTableProps,
  IListTableToolbarProps,
  ISelectChangeEvent,
  ISortStatus,
  ITableData,
  ListTable,
  Pagination,
  Select,
  Sort,
  dissoc,
  equals,
  fromPairs,
  gte,
  isNil,
  isNilEmpty,
  keys,
  mergeRight,
  prop,
  split,
  useControlled,
} from '@kakaoent/ops-design';
import cn from 'classnames';
import {ReactNode} from 'react';
import type {IPagination} from '../../../types';

interface IPaginationListProps<T extends ITableData>
  extends Pick<
      IListTableProps<T>,
      | 'dataList'
      | 'disabled'
      | 'identifier'
      | 'isLoading'
      | 'onChangeSelected'
      | 'onChangeSort'
      | 'onClickRow'
      | 'selected'
    >,
    Pick<IListTableBodyProps<T>, 'columns' | 'noDataIndication' | 'useSelect'>,
    Pick<IListTableToolbarProps, 'buttons'> {
  className?: string;
  pagination?: Partial<IPagination>;
  tableClassName?: string;
  skeleton?: boolean;
  useToolbar?: boolean;
  onChangePagination?: (pagination: IPagination) => void;
  renderFooterButtons?: () => ReactNode;
}

const PaginationList = function <T extends ITableData>({
  buttons,
  className,
  columns,
  dataList,
  disabled,
  identifier,
  isLoading,
  noDataIndication,
  onClickRow,
  onChangePagination,
  onChangeSelected,
  pagination,
  renderFooterButtons,
  selected,
  skeleton,
  tableClassName,
  useSelect,
  useToolbar = true,
}: IPaginationListProps<T>) {
  const [_values, _setValues] = useControlled<Partial<IPagination>>({
    valueProp: pagination,
    defaultValue: {
      paginationOffset: 0,
      paginationLimit: 20,
      paginationSort: '',
      paginationTotalCount: 0,
    },
  });

  // 정렬 변경 -> pagination 정보에 포함하여 콜백 호출
  const _onChangeSort = function (sortStatus: ISortStatus) {
    const [prevSortKey, prevSort] = split(',', _values.paginationSort ?? '');
    sortStatus = mergeRight({[prevSortKey]: prevSort as Sort}, sortStatus);

    // sortKey가 2개 이상이면 이전 sortKey를 제거한다.(1개로 유지)
    if (keys(sortStatus).length > 1) {
      sortStatus = dissoc(prevSortKey, sortStatus);
    }

    const sortKey = keys(sortStatus)[0];
    const sort = prop(sortKey, sortStatus);
    const sortStr = isNil(sort) ? '' : `${sortKey},${sort}`;

    const nextSort = {
      ..._values,
      paginationSort: sortStr,
    };

    _setValues(nextSort);
    onChangePagination?.(nextSort);
  };

  // 페이지당 아이템 수 변경
  const _onChangeLimit = function (event: ISelectChangeEvent) {
    const nextLimit = Number(event.target.value);
    const prevOffset = _values.paginationOffset;
    let nextOffset = Math.floor((prevOffset ?? 0) / nextLimit) * nextLimit;
    if (gte(nextOffset, _values.paginationTotalCount ?? 0)) {
      nextOffset =
        Math.floor((_values.paginationTotalCount ?? 0) / nextLimit) * nextLimit;
    }

    const nextPagination = {
      ..._values,
      paginationLimit: nextLimit,
      paginationOffset: nextOffset,
    };

    _setValues(nextPagination);
    onChangePagination?.(nextPagination);
  };

  // 페이지 변경
  const _onChangePage = function (page: number) {
    const nextPage = {
      ..._values,
      paginationOffset: (_values.paginationLimit ?? 20) * (page - 1),
    };

    _setValues(nextPage);
    onChangePagination?.(nextPage);
  };

  function _getTotalPage() {
    const totalCount = pagination?.paginationTotalCount ?? 0;
    const limit = pagination?.paginationLimit ?? 0;
    const result = Math.ceil(totalCount / limit);

    return equals(totalCount, 0) || equals(limit, 0) || Number.isNaN(result)
      ? 1
      : result;
  }

  return (
    <ListTable
      className={className}
      dataList={dataList ?? []}
      disabled={disabled}
      totalCount={_values.paginationTotalCount}
      identifier={identifier}
      isLoading={isLoading}
      onChangeSelected={onChangeSelected}
      onChangeSort={_onChangeSort}
      onClickRow={onClickRow}
      selected={selected}
      sorted={
        !isNilEmpty(_values.paginationSort)
          ? fromPairs([
              split(',', _values.paginationSort ?? '') as [string, any],
            ])
          : {}
      }
    >
      <Hidden hidden={!useToolbar}>
        <ListTable.Toolbar viewSize="sm" buttons={buttons} />
      </Hidden>
      <ListTable.Table
        className={tableClassName}
        columns={columns}
        noDataIndication={noDataIndication}
        cellPy={0.875}
        cellPx={1.25}
        skeleton={skeleton ? Number(_values.paginationLimit) : 0}
        useSelect={useSelect}
      />

      <div
        className={cn('grid', 'grid-cols-5', 'justify-center', 'items-center')}
      >
        <Select
          name="pageOff"
          options={[
            {
              label: '20개',
              value: 20,
            },
            {
              label: '50개',
              value: 50,
            },
            {
              label: '100개',
              value: 100,
            },
          ]}
          onChange={_onChangeLimit}
          value={_values.paginationLimit}
          className={cn(
            'col-start-1',
            'col-span-1',
            'inline-flex',
            'w-[9.5rem]'
          )}
        />

        <div
          className={cn('col-start-3', 'col-span-1', 'flex', 'justify-center')}
        >
          <Pagination
            totalPage={_getTotalPage()}
            page={
              (pagination?.paginationOffset ?? 0) /
                (pagination?.paginationLimit ?? 0) +
              1
            }
            sectionSize={10}
            onChangePage={_onChangePage}
            hideArrow={true}
          />
        </div>

        {isNil(renderFooterButtons) ? <></> : renderFooterButtons()}
      </div>
    </ListTable>
  );
};

export default PaginationList;
