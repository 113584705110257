'use client';
import {Footer, IFooterProps, Text} from '@kakaoent/ops-design';
import cn from 'classnames';

export interface IPartnerFooterProps extends IFooterProps {
  className?: string;
}

export default function PartnerFooter({
  className,
  p,
  px = 1.25,
  py = 2,
  pt = 6.75,
  pr,
  pb,
  pl,
  ...others
}: IPartnerFooterProps) {
  return (
    <Footer
      className={cn(
        'w-full',
        'flex-shrink-0',
        'flex',
        'justify-center',
        className
      )}
      p={p}
      px={px}
      py={py}
      pt={pt}
      pr={pr}
      pb={pb}
      pl={pl}
      {...others}
    >
      <div className={cn('flex', 'flex-col', 'items-center', 'gap-[.125rem]')}>
        <Text fontColor="primary" fontSize={12} className={cn('opacity-30')}>
          ⓒKakao Entertainment Corp.
        </Text>
        <Text
          paragraph
          fontSize={'12'}
          fontColor={'primary'}
          className={cn('mt-[0.25rem]', 'mx-[.5rem]', 'text-center')}
        >
          <Text className={cn('opacity-30')}>
            This site is protected by reCAPTCHA and the Google
          </Text>
          <a
            href="https://policies.google.com/privacy"
            className={cn('opacity-100')}
          >
            Privacy Policy
          </a>{' '}
          <Text className={cn('opacity-30')}>and</Text>
          <a
            href="https://policies.google.com/terms"
            className={cn('opacity-100')}
          >
            Terms of Service
          </a>{' '}
          <Text className={cn('opacity-30')}>apply.</Text>
        </Text>
      </div>
    </Footer>
  );
}
