import {Text} from '@kakaoent/ops-design';
import cn from 'classnames';
import React from 'react';

export interface IRecaptchaTextProps {
  className?: string;
}

const RecaptchaText: React.FC<IRecaptchaTextProps> = function ({className}) {
  return (
    <Text paragraph fontSize={'12'} className={cn('text-black/30', className)}>
      This site is protected by reCAPTCHA and the Google
      <a
        href="https://policies.google.com/privacy"
        className={cn('text-black')}
      >
        Privacy Policy
      </a>{' '}
      and
      <a href="https://policies.google.com/terms" className={cn('text-black')}>
        Terms of Service
      </a>{' '}
      apply.
    </Text>
  );
};

export default RecaptchaText;
