import {ITileProps, Tile} from '@kakaoent/ops-design';
import cn from 'classnames';
import React, {forwardRef} from 'react';

export interface IListTileProps extends ITileProps {}

const ListTile = forwardRef(function ListTile(
  {className, ...others}: IListTileProps,
  ref: React.Ref<HTMLDivElement>
) {
  return (
    <Tile
      ref={ref}
      className={cn('bg-black', 'bg-opacity-[0.06]', className)}
      px={1.25}
      py={0.75}
      {...others}
    />
  );
});

export default ListTile;
