'use client';

import {
  Button,
  Heading,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Text,
  Tile,
} from '@kakaoent/ops-design';
import cn from 'classnames';
import React from 'react';
import {getEnv} from '../../../utils/getEnv';

export interface ModalPrivacyProps {
  open?: boolean;
  onClose?: () => void;
}

const ModalPrivacy: React.FC<ModalPrivacyProps> = function ({
  open,
  onClose = () => {},
}) {
  return (
    <Modal open={open ?? false} onClose={onClose}>
      <Tile
        className={cn(
          'cardBox',
          'm-auto',
          'w-[51.25rem]',
          'absolute',
          'top-1/2',
          'left-1/2',
          '-translate-x-1/2',
          '-translate-y-1/2'
        )}
      >
        <Button
          className={cn(
            'absolute',
            'top-[1.5rem]',
            'right-[1.5rem]',
            'z-10',
            'min-h-0'
          )}
          variant={'text'}
          p={0}
          onClick={onClose}
        >
          <img
            alt="close"
            src={'/icons/ic_popup_close.svg'}
            width={24}
            height={24}
          />
        </Button>
        <div className={cn('cardBoxBody', 'p-10', 'relative')}>
          <Heading level={4} className={cn('font-bold')}>
            개인정보 수집 및 이용 동의
          </Heading>

          <p className={cn('mt-2', 'text-el2')}>
            주식회사 카카오엔터테인먼트가 제공하는 카카오페이지 파트너사이트는
            아래의 목적으로 개인정보를 수집 및 이용하며, 파트너사의 개인정보를
            안전하게 취급하는데 최선을 다합니다.
          </p>

          <label className={cn('mt-8', 'text-el2')}>
            {'[필수] 개인정보 수집 및 이용 동의'}
          </label>
          <Table className={cn('mt-4')} border={1}>
            <colgroup>
              <col width={1} />
              <col width={1} />
              <col width={1} />
            </colgroup>
            <TableHead>
              <TableRow>
                <TableCell className={cn('text-center')}>이용 목적</TableCell>
                <TableCell className={cn('text-center')}>수집 항목</TableCell>
                <TableCell className={cn('text-center')}>
                  보유 및 이용 기간
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell p={0.5}>
                  <Text>
                    회원가입, 계약 이행 및 서비스 제공에 따른 요금정산, 민원처리
                  </Text>
                </TableCell>
                <TableCell p={0.5}>
                  <Text>
                    {
                      '아이디, 비밀번호, 담당자명, 연락처(또는 휴대전화번호), 이메일 주소, 정산정보(전자 세금계산서용 이메일 주소, 계좌은행명, 계좌번호, 예금주, 통장사본)'
                    }
                  </Text>
                </TableCell>
                <TableCell className={'text-center'} p={0.5}>
                  <Text fontSize={18} weight={700}>
                    탈퇴일로부터 30일
                  </Text>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>

          <span className={cn('text-el2', 'mt-8')}>
            * 개인정보 수집·이용에 대한 동의를 거부할 권리가 있으며, 동의를
            거부할 경우 서비스 이용에 제한을 받을 수 있습니다.
          </span>
          <span className={cn('text-el2', 'mt-4')}>
            * 그 외 개인정보 처리에 대한 자세한 내용은{' '}
            <a
              className={cn('underline', 'text-blue')}
              href={`${getEnv('URL_KAKAOENT')}/privacy/policy?service=kakaopage_partner`}
              target="_blank"
              rel="noreferrer"
            >
              개인정보처리방침
            </a>
            을 참고하시기 바랍니다.
          </span>
        </div>
      </Tile>
    </Modal>
  );
};

export default ModalPrivacy;
