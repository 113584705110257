'use client'; // Error components must be Client Components

import {Button, Heading, Text, Tile, isEmpty} from '@kakaoent/ops-design';
import type {Scope} from '@sentry/nextjs';
import {isAxiosError} from 'axios';
import cn from 'classnames';
import {useEffect, useState} from 'react';
import {getApiErrorText} from '../../../api';
import {instQueryClient} from '../../../api/_queryClient5';
import type {ApiError} from '../../../types';
import {waitSentry} from '../../../utils/waitSentry';

export interface IErrorPageProps {
  className?: string;
  component?: string;
  error: (Error & {digest?: string}) | ApiError;
  reset: () => void;
}

export default function ErrorPage({
  className,
  component,
  error,
}: IErrorPageProps) {
  const [_errorMesage, _setErrorMessage] = useState('');

  useEffect(() => {
    _setErrorMessage(
      getApiErrorText(error as ApiError, `요청사항을 처리하는데\n실패했습니다.`)
    );

    // Log the error to an error reporting service
    waitSentry().then(Sentry => {
      if (!isAxiosError(error)) {
        Sentry.withScope((scope: Scope) => {
          scope.setLevel('error');
          if (!isEmpty(component ?? '')) {
            scope.setTag('component', component);
          }
          scope.captureException(error);
        });
      }
    });
  }, [error]);

  function onClickReset() {
    instQueryClient().clear();
    window.location.reload();
  }

  return (
    <Tile
      className={cn(
        'h-full',
        'flex',
        'flex-col',
        'gap-[1.5rem]',
        'items-center',
        'justify-center',
        className
      )}
    >
      <Tile className={cn('flex', 'flex-col', 'gap-[.25rem]', 'items-center')}>
        <Heading
          className={cn(
            'w-full',
            'break-words',
            'text-center',
            'whitespace-pre-line'
          )}
          level={4}
          bold
        >
          잠시 후 다시 시도해주세요.
        </Heading>
        <Text
          paragraph
          className={cn(
            'w-full',
            'break-words',
            'text-center',
            'whitespace-pre-line'
          )}
          fontSize={14}
          fontColor={'tertiary'}
        >
          {_errorMesage}
        </Text>
      </Tile>
      <Button className={cn('w-[20rem]')} onClick={onClickReset}>
        다시 시도
      </Button>
    </Tile>
  );
}
