import {Text} from '@kakaoent/ops-design';
import moment from 'moment';
import React, {useEffect, useState} from 'react';

interface ITimerTextProps {
  seconds: number;
  timeOverText?: string;
}

const TimerText: React.FC<ITimerTextProps> = function ({
  seconds,
  timeOverText,
}) {
  const [_remainSeconds, _setRemainSeconds] = useState(0);

  useEffect(() => {
    _setRemainSeconds(seconds);

    const intervalKey = setInterval(
      startUnixSeconds => {
        const endUnixSeconds = moment().unix();

        const remainSeconds = seconds - (endUnixSeconds - startUnixSeconds);
        if (remainSeconds < 0) {
          return clearInterval(intervalKey);
        }

        _setRemainSeconds(remainSeconds);
      },
      1000,
      moment().unix()
    );

    return () => clearInterval(intervalKey);
  }, [seconds]);

  // render
  const isTimeOver = _remainSeconds <= 0;
  return (
    <Text fontColor={isTimeOver ? 'invalid' : 'secondary'} fontSize={14}>
      {isTimeOver && timeOverText
        ? timeOverText
        : `남은 시간 : ${secondsToTime(_remainSeconds)}`}
    </Text>
  );
};

function toTimeString(time: number) {
  return time < 10 ? '0' + time : '' + time;
}

function secondsToTime(seconds: number) {
  const hh = Math.floor(seconds / 60 / 60);
  const mm = Math.floor(seconds / 60);
  const ss = seconds % 60;

  return (
    (hh === 0 ? '' : `${toTimeString(hh)}:`) +
    `${toTimeString(mm)}:${toTimeString(ss)}`
  );
}

export default TimerText;
