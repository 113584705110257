import cn from 'classnames';
import Image from 'next/image';
import {path} from 'ramda';
import React, {HTMLAttributes} from 'react';
import {isClientBrowser} from '../../../utils/network';

interface IBrowserFilterProps extends HTMLAttributes<HTMLDivElement> {}

function isIE() {
  if (isClientBrowser()) {
    return (
      (navigator && navigator.userAgent.indexOf('MSIE') !== -1) ||
      !!path(['documentMode'], document)
    );
  }

  return false;
}

const BrowserFilter: React.FC<IBrowserFilterProps> = function ({children}) {
  const _onClickChromeDownload = function () {
    window.open('https://www.google.com/chrome/');
  };

  if (isIE()) {
    return (
      <main className={cn('h-full', 'flex', 'flex-col', 'py-36')}>
        <Image
          alt="logo"
          width={130}
          height={60}
          src={'/images/img-kakaopage-logo.svg'}
        />
        <span className={cn('mt-7')}>파트너 사이트</span>
        <h1 className={cn('mt-16', 'font-bold')}>
          크롬 브라우저를 사용해보세요.
        </h1>
        <p className={cn('text-center', 'mt-3')}>
          카카오페이지 파트너사이트는 크롬 브라우저에서 최적화 되어 있습니다.
          <br />
          안정적인 서비스 이용을 위해 설치 후, 이용해주시기 바랍니다.
          <br />
          아래의 아이콘을 클릭하면 설치 화면으로 이동 합니다.
        </p>
        <button
          style={{borderColor: 'rgb(211, 211, 211)'}}
          className={cn(
            'mt-16',
            'flex',
            'flex-row',
            'items-center',
            'border',
            'rounded',
            'bg-white',
            'rounded',
            'px-[2.5rem]',
            'py-[1rem]'
          )}
          onClick={_onClickChromeDownload}
        >
          <Image
            alt="chrome"
            width={28}
            height={28}
            src={'/images/img_chrome.png'}
          />
          <span className={cn('ml-3', 'font-bold')}>크롬 다운로드</span>
        </button>
      </main>
    );
  }

  return <>{children}</>;
};

export default BrowserFilter;
