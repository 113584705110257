import {Input, InputBox, omit, useControlled} from '@kakaoent/ops-design';
import cn from 'classnames';
import moment from 'moment';
import React, {FC, InputHTMLAttributes, forwardRef} from 'react';
import ReactDatePicker, {ReactDatePickerProps} from 'react-datepicker';

export interface IDatePickerProps
  extends Omit<ReactDatePickerProps, 'customInput'> {
  onlyCalendar?: boolean;
}

const CustomInput = forwardRef<
  HTMLInputElement,
  Omit<InputHTMLAttributes<HTMLInputElement>, 'type'> & {onlyCalendar?: boolean}
>(function CustomInput({onlyCalendar, ...inputProps}, ref) {
  return (
    <InputBox className={inputProps.className} viewSize={'md'} px={0}>
      <Input
        className={cn('flex-1')}
        ref={ref}
        {...omit(['className'], inputProps ?? {})}
        readOnly={onlyCalendar}
      />
      <img
        className={cn('ml-[0.75rem]', 'mr-[1.25rem]', {
          'opacity-15': inputProps.disabled,
        })}
        alt="calendar"
        src={'/icons/ic_16_cal_b.svg'}
      />
    </InputBox>
  );
});

const DatePicker: FC<IDatePickerProps> = function ({
  className,
  dateFormat = 'yyyy. MM. dd HH:mm',
  maxDate = moment('9999-12-31').toDate(),
  name,
  onChange,
  onlyCalendar = false,
  value,
  disabled,
  ...others
}) {
  const [_value, _setValue] = useControlled<string>({
    valueProp: value,
    defaultValue: '',
  });

  function _onChange(
    date: Date | null,
    event: React.SyntheticEvent<any, Event> | undefined
  ) {
    onChange?.(date, event);
  }

  return (
    <ReactDatePicker
      customInput={<CustomInput onlyCalendar={onlyCalendar} />}
      className={cn('outline-none', 'h-[2.75rem]', className)}
      dateFormat={dateFormat}
      maxDate={maxDate}
      onChange={_onChange}
      disabled={disabled}
      {...others}
    />
  );
};

export default DatePicker;
