'use client';

import {isNilEmpty} from '@kakaoent/ops-design';
import cn from 'classnames';
import {FC, useEffect, useState} from 'react';
import {getEnv} from '../../../utils/getEnv';

interface IImageKageThumbnailProps {
  alt: string;
  kageId: string;
  fill?: boolean;
  width?: string | number;
  height?: string | number;
  loading?: 'eager' | 'lazy';
  className?: string;
}

const FALLBACK_SRC =
  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGUAAACZCAYAAAA2LPStAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAIfSURBVHgB7d2xitRAAIDh2cXCgyssrNTCRnwsn8FH0Cfw9cTibKzPJizs7RI32Ksg3H3J/h8EEpgi8JPAzBSzOxwOH87n85fdbvdi5Knd7ff7T7tpmu4uD29HFPdLlHlEMu9HOEUBFQVUFFBRQEUBFQVUFFBRQEUBFQVUFFBRQEUBFQVUFFBRQEUBFQVUFFBRQEUBFQVUFFBRQEUBFQVUFFBRQEUBFQVUFFBRQEUBFQVUFFBRQEUBFQVUFFBRQEUBFQVUFFBRQEUBFQVUFFBRQEUBFQVUFFBRQEUBFQVUFFBRQEUBFQVUFFBRQM8G6Ou3H+OxvH/3emj6UkBFARUFVBRQUUBFARUFVBRQUUBFARUFVBRQUUBFARUFRO6n/K83r16Om5vnfx33cHoYok1GWYLc3t78cczpdBr7o3nq+1X+vpYgx+NxqK4uih5kcVVR1hBkcTVR1hJkcRVR1hRksfkoawuy2HSUNQZZbDbKWoMsNjl5/B3EnBj+i900Tet9+22aW5AEFQVUFFBRQEUBFQVUFFBRQEUBFQVUFFBRQEUBFQVUFFBRQEUBFQVUFFBRQEUBFQVUFFBRQEUBFQVUFFBRQEUBFQVUFFBRQEUBFQVUFFBRQEUBFQVUFFBRQEUBFQVUFFBRQEUBFQVUFFBRQEUBFQVUFFBRQEUBFQVUFFBRQEUBFQVUFNAS5eeI5Pt+nuePy83Ik7u0uL9cn38Bd3hmAiRtZ8cAAAAASUVORK5CYII=';

const ImageKageThumbnail: FC<IImageKageThumbnailProps> = function ({
  alt,
  kageId,
  fill,
  width,
  height,
  loading,
  className,
}) {
  const [_kageId, _setKageId] = useState<string>(kageId);

  useEffect(() => {
    _setKageId(kageId);
  }, [kageId]);

  const isCanLoadImage =
    !isNilEmpty(getEnv('URL_KAGE')) && !isNilEmpty(_kageId);

  return (
    <img
      alt={alt}
      src={
        isCanLoadImage ? `${getEnv('URL_KAGE')}?kid=${_kageId}` : FALLBACK_SRC
      }
      sizes={fill ? '100%' : undefined}
      width={width}
      height={height}
      loading={loading ?? 'lazy'}
      className={cn(
        {'w-full': fill, 'h-full': fill, 'object-fill': fill},
        className
      )}
      onError={() => {
        _setKageId('');
      }}
    />
  );
};

export default ImageKageThumbnail;
