import {Heading, Text, Tile, map} from '@kakaoent/ops-design';
import cn from 'classnames';
import React from 'react';

const ContactGrid: React.FC<{
  title: string;
  contacts: {depart: string; email: string}[];
}> = function (props) {
  const {title, contacts} = props;

  const _onClickMail = function (email: string) {
    window.location.href = `mailto:${email}`;
  };

  return (
    <Tile className={cn('flex', 'flex-col', 'md:flex-row', 'py-[2.5rem]')}>
      <Heading level={4} className={cn('w-[12rem]', 'mb-[2.5rem]', 'md:mb-0')}>
        {title}
      </Heading>
      <Tile
        className={cn(
          'flex-1',
          'grid',
          'grid-cols-1',
          'md:grid-cols-2',
          'gap-[2.5rem]'
        )}
      >
        {map(
          contact => (
            <Tile key={contact.depart}>
              <Text paragraph fontSize={12} weight={500}>
                {contact.depart}
              </Text>
              <Text
                paragraph
                fontSize={12}
                fontColor={'secondary'}
                className={cn('mt-[.5rem]', 'underline')}
              >
                <a
                  className={cn('cursor-pointer')}
                  onClick={() => _onClickMail(contact.email)}
                >
                  {contact.email}
                </a>
              </Text>
            </Tile>
          ),
          contacts
        )}
      </Tile>
    </Tile>
  );
};
export default ContactGrid;
